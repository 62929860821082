const name = 'generator_fuel_remainder_adjustment';

const shape = {
  quantity: '',
  quantity_unit: '',
  remainder_after: '',
  remainder_after_unit: '',
  remainder_before: '',
  remainder_before_unit: '',
  created_at: '',
  current_allocation: '',
  prev_allocation: '',
  generator_fuel: {
    type: 'generator_fuels',
    jsonApi: 'hasOne',
  },
  generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
  certificate: {
    type: 'certificates',
    jsonApi: 'hasOne',
  },
  generation_entry: {
    type: 'generation_entries',
    jsonApi: 'hasOne',
  },
  notes: {
    type: 'note',
    jsonApi: 'hasMany',
  },
  dups_quantities: [],
};

const model = {
  name,
  shape,
};

export default model;
